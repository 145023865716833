import {Commands, Context, Router} from '@vaadin/router';
import './components/login-panel';
import './components/chat-menu';
import {AuthGuard} from './auth/authGuard';

export class AppRouter {
  private router: Router;

  constructor(outlet: HTMLElement | null) {
    if (!outlet) {
      throw Error('Router outlet is not defined');
    }
    this.router = new Router(outlet);
    this.configureRoutes();
  }

  private configureRoutes(): void {
    const authGuard = (
      _context: Context,
      commands: Commands
    ): void | ReturnType<Commands['redirect']> => {
      if (!AuthGuard.isAuthenticated()) {
        return commands.redirect('/login');
      }
    };

    this.router.setRoutes([
      {path: '/', redirect: '/chat'},
      {path: '/login', component: 'login-panel'},
      {path: '/user', component: 'user-panel', action: authGuard},
      {
        path: '/chat',
        component: 'chat-menu',
        action: async (_context, commands) => {
          if (!AuthGuard.isAuthenticated()) {
            return commands.redirect('/login');
          }
          return undefined;
        },
      },
    ]);
  }

  static init(): void {
    document.addEventListener('DOMContentLoaded', () => {
      const outlet = document.querySelector('main');
      new AppRouter(outlet);
    });
  }
}

AppRouter.init();
